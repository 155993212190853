<template>
  <div class="list">
    <div class="list_search">
      <van-search
        v-model="value"
        shape="round"
        clearable
        placeholder="搜作品/找艺术家"
        @search="Search"
      />
    </div>
    <div class="cont">
      <div class="list_row">
        <div class="list_col">
          <div class="title">类别</div>
          <ul>
            <li
              :class="categoryProperty === '' ? 'act' : ''"
              @click="categoryPropertyClick('')"
            >
              全部
            </li>
            <li
              :class="categoryProperty === li.Id ? 'act' : ''"
              v-for="(li, i) in lxList"
              :key="i"
              @click="categoryPropertyClick(li.Id)"
            >
              {{ li.Name }}
            </li>
          </ul>
        </div>
        <div class="list_col">
          <div class="title">排序</div>
          <ul>
            <li :class="sortExp === '' ? 'act' : ''" @click="sortExpClick('')">
              默认
            </li>
            <li
              :class="sortExp === li.value ? 'act' : ''"
              v-for="(li, i) in sorts"
              :key="i"
              @click="sortExpClick(li.value)"
            >
              {{ li.lable }}
            </li>
          </ul>
        </div>
        <!-- <div class="list_col">
          <div class="title">场景</div>
          <ul>
            <li
              :class="sceneProperty === '' ? 'act' : ''"
              @click="scenePropertyClick('')"
            >
              全部
            </li>
            <li
              :class="sceneProperty === li.Id ? 'act' : ''"
              v-for="(li, i) in cjList"
              :key="i"
              @click="scenePropertyClick(li.Id)"
            >
              {{ li.Name }}
            </li>
          </ul>
        </div> -->
        <!-- <div class="list_col">
          <div class="title">艺术家</div>
          <ul>
            <li :class="artistId === '' ? 'act' : ''" @click="artistClick('')">
              全部
            </li>
            <li
              :class="artistId === li.Id ? 'act' : ''"
              v-for="(li, i) in ysjList"
              :key="i"
              @click="artistClick(li.Id)"
            >
              {{ li.SupplierName }}
            </li>
          </ul>
        </div> -->
        <div class="list_col">
          <div class="title">价格</div>
          <ul>
            <li
              :class="priceExp === '' ? 'act' : ''"
              @click="priceExpClick('')"
            >
              全部
            </li>
            <li
              :class="priceExp === li.value ? 'act' : ''"
              v-for="(li, i) in priceList"
              :key="i"
              @click="priceExpClick(li)"
            >
              {{ li.lable }}
            </li>
          </ul>
        </div>
        <!-- <div class="list_col">
          <div class="title">尺寸</div>
          <ul>
            <li
              :class="artSizeId === '' ? 'act' : ''"
              @click="artSizeClick('')"
            >
              全部
            </li>
            <li
              :class="artSizeId === li.Id ? 'act' : ''"
              v-for="(li, i) in artSizeList"
              :key="i"
              @click="artSizeClick(li.Id)"
            >
              {{ li.Name }}
            </li>
          </ul>
        </div> -->
        <div class="list_col">
          <div class="title">年份</div>
          <ul>
            <li
              :class="artYearId === '' ? 'act' : ''"
              @click="artYearClick('')"
            >
              全部
            </li>
            <li
              :class="artYearId === li.Id ? 'act' : ''"
              v-for="(li, i) in artYearList"
              :key="i"
              @click="artYearClick(li.Id)"
            >
              {{ li.Name }}
            </li>
          </ul>
        </div>
      </div>
      <div>
        <div class="works" v-if="artList.length > 0">
          <van-list
            v-model="loading"
            :finished="finished"
            finished-text="没有更多了"
            @load="getQueryNewestArtExhibition"
          >
            <ul>
              <li v-for="(a, i) in artList" @click="artClick(a.ArtId)" :key="i">
                <img v-lazy="a.ArtImg" alt="" srcset="" />
                <p class="title decimalPoint-1">{{ a.ArtTitle }}</p>
                <p></p>

                <p class="price">
                  <span v-if="a.DefaultPrice!=null"> ￥ </span>
                  {{ a.DefaultPrice }}
                  <span style="color: #000; margin-left: 10px">{{
                    a.ArtistName
                  }}</span>
                </p>
              </li>
            </ul>
          </van-list>
        </div>
        <div v-if="artList.length <= 0">
          <no-data></no-data>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import noData from "@/components/noData";
import {
  queryArtProperty,
  queryArtist,
  queryNewestArtExhibition,
  queryArtSize,
  queryArtYear,
} from "@/api/art";
export default {
  components: { noData },
  computed: {},
  watch: {},
  data() {
    return {
      value: "",
      artList: [],
      pageSize: 10,
      pageIndex: 1,
      total: 0,
      loading: false,
      finished: false,
      cjList: [], //场景0 ，类型2
      lxList: [], //场景0 ，类型2
      ysjList: [],
      artSizeList: [],
      artYearList: [],
      sorts: [
        { value: "a.DefaultPrice desc", lable: "价格由高到低" },
        { value: "a.DefaultPrice asc", lable: "价格由低到高" },
      ],
      priceList: [
        { value: "0-800", minPrice: "", maxPrice: 800, lable: "800元以下" },
        {
          value: "800-2000",
          minPrice: 800,
          maxPrice: 2000,
          lable: "800-2000元",
        },
        {
          value: "2000-5000",
          minPrice: 2000,
          maxPrice: 5000,
          lable: "2000-5000元",
        },
        {
          value: "5000-10000",
          minPrice: 5000,
          maxPrice: 10000,
          lable: "5000-10000元",
        },
        {
          value: "10000-999999999",
          minPrice: 10000,
          maxPrice: "",
          lable: "10000元以上",
        },
      ],
      sortExp: "", // 排序 价格升序 a.DefaultPrice asc 降序：a.DefaultPrice desc
      artistId: "", // 艺术家
      sceneProperty: "", // 场景
      categoryProperty: "", // 分类
      artSizeId: "",
      artYearId: "",
      minPrice: "",
      maxPrice: "",
      priceExp: "",
    };
  },
  created() {
    if (this.$route.params.keyWords) {
      this.value = this.$route.params.keyWords;
    }
    this.getQueryNewestArtExhibition();
    this.init();
  },
  mounted() {},
  methods: {
    artClick(id) {
      let url =
        "/H5/art/artistsDetails?id=" +
        id +
        "&artUserId=" +
        localStorage.getItem("artUserId");
      this.$router.push({ path: url });
    },
    priceExpClick(val) {
    
      if (val) {
        this.minPrice = val.minPrice;
        this.maxPrice = val.maxPrice;
        this.priceExp = val.value;
      } else {
        this.maxPrice="";
        this.minPrice="";
        this.priceExp = "";
      }

      this.newes();
    },
    artSizeClick(val) {
      this.artSizeId = val;
      this.newes();
    },
    artYearClick(val) {
      this.artYearId = val;
      this.newes();
    },
    categoryPropertyClick(val) {
      this.categoryProperty = val;
      this.newes();
    },
    sortExpClick(val) {
      this.sortExp = val;
      this.newes();
    },
    scenePropertyClick(val) {
      this.sceneProperty = val;
      this.newes();
    },
    artistClick(val) {
      this.artistId = val;
      this.newes();
    },
    newes() {
      this.loading = false;
      this.finished = false;
      this.pageIndex = 1;
      this.artList = [];
      this.getQueryNewestArtExhibition();
    },
    async getQueryNewestArtExhibition() {
      let parm = {
        keyWords: this.value,
        sortExp: this.sortExp,
        artistId: this.artistId,
        sceneProperty: this.sceneProperty,
        categoryProperty: this.categoryProperty,
        pageIndex: this.pageIndex,
        pageSize: this.pageSize,
        artSizeId: this.artSizeId,
        artYearId: this.artYearId,
        minPrice: this.minPrice,
        maxPrice: this.maxPrice,
      };
      const res = await queryNewestArtExhibition(parm);
      this.artList = this.artList.concat(res.response.data);
      this.pageIndex++;
      // 加载状态结束
      this.loading = false;
      // 数据全部加载完成
      if (this.artList.length >= res.response.dataCount) {
        this.finished = true;
      }
    },
    async getQueryArtProperty(category) {
      let parm = "?category=" + category;
      // console.log(parm, '')
      const res = await queryArtProperty(parm);
      if (res.success == true) {
        if (category == 0) {
          this.cjList = res.response;
        }
        if (category == 2) {
          this.lxList = res.response;
        }
      }
    },
    async queryArtSize() {
      const res = await queryArtSize();
      if (res.success == true) {
        this.artSizeList = res.response;
      }
    },
    async queryArtYear() {
      const res = await queryArtYear();
      if (res.success == true) {
        this.artYearList = res.response;
      }
    },
    async init() {
      this.getQueryArtProperty(0);
      this.getQueryArtProperty(2);
      this.queryArtSize();
      this.queryArtYear();
      const res1 = await queryArtist();
      if (res1.success == true) {
        this.ysjList = res1.response;
      }
    },
    Search() {
      // this.$router.push({
      //   path: "/shop/goods/list",
      //   query: {
      //     key: this.value,
      //   },
      // });
      this.newes();
    },
  },
};
</script>
<style lang="less" scoped>
.list {
  padding: 0 15px;
  min-height: 100vh;
  background-color: #fff;
  .list_search {
    padding: 6px 0;
    /deep/.van-search {
      padding: 0;
      width: 100%;
      .van-search__content {
        background-color: #eeeeed;
      }
      .van-field__control::placeholder {
        color: #999;
      }
    }
  }
  .cont {
    .list_row {
      .list_col {
        display: flex;
        align-items: center;
        align-content: center;
        margin: 15px 0;
        .title {
          font-size: 15px;
          font-weight: bold;
          width: 60px;
          flex: 0 0 60px;
        }
        ul {
          display: -webkit-box;
          overflow: auto;
          -webkit-overflow-scrolling: touch;
          &::-webkit-scrollbar {
            display: none; /* Chrome Safari */
          }
          li {
            color: #666666;
            min-width: 20px;
            padding: 0 10px;
            height: 26px;
            line-height: 26px;
          }
          .act {
            background: #eeeeed;
            border-radius: 4px;
            color: #333333;
          }
        }
      }
    }
    .works {
      ul {
        display: inline-block;
        -moz-column-count: 2; /* Firefox */
        -webkit-column-count: 2; /* Safari 和 Chrome */
        column-count: 2;
        -moz-column-gap: 15px;
        -webkit-column-gap: 15px;
        column-gap: 15px;
        li {
          break-inside: avoid;
          img {
            width: 100%;
            border-radius: 6px;
          }
          .title {
            font-size: 15px;
            font-weight: 800;
            line-height: 1.5;
            margin: 15px 0 10px;
          }
          .price {
            font-size: 15px;
            color: #fe4a4a;
            font-weight: bold;
            line-height: 1;
            margin-bottom: 15px;
            span {
              font-weight: normal;
              font-size: 0.625rem;
            }
          }
        }
      }
    }
  }
}
</style>
